<template>
  <div>
    <Breadcrumbs page_title="library" :items="breadcrumbs" />
    <template>
      <v-container fluid>
        <v-row v-if="loading" class="mt-5">
          <v-col cols="2" v-for="n in 6" :key="n">
            <v-skeleton-loader
              class="mx-auto"
              max-height="180"
              type="image"
            ></v-skeleton-loader>
          </v-col>
        </v-row>

        <v-row class="d-flex">
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="2"
            v-for="item in items"
            :key="item.id"
            class="library lg5-custom"
          >
            <v-card
              class="mx-auto library_card pa-4"
              style="border-radius: 16px; padding-top: 12px"
              elevation="3"
              @click="gosubLibrary(item)"
            >
              <v-img
                gradient="to top right, rgba(66,66,66,.43), rgba(66,66,66,.43)"
                :src="item.orgImage"
                height="160px"
                class="library_img"
              >
                <v-card-title class="cardTitle">
                  {{ item.topicName }}
                </v-card-title>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      loading: true,
      userID: "",
      companyID: "",
      breadcrumbs: [
        // {
        //   sidebar_tilte: null,
        // },
        {
          text: "library",
        },
      ],
      items: [],
    };
  },
  mounted() {
    this.getTopic();
  },
  methods: {
    async getTopic() {
      let self = this;
      const res = await axios.get(
        `${self.web_urlV4}Topic/GetTopicsMobile?companyID=` +
          localStorage.getItem("companyID") +
          `&userID=` +
          parseInt(localStorage.getItem("UserID"))
      );

      self.items = res.data.data.map((v, i) => ({
        ...v,
        no: i + 1,
      }));
      this.loading = false;
    },
    gosubLibrary(item) {
      const temp = {
        articleCount: item.articleCount !== 0,
        soundCount: item.soundCount !== 0,
        videoCount: item.videoCount !== 0,
      };
      this.$router.push({
        path: "/library/" + item.topicName.split("?").join(""),
        query: { topic_id: item.id, itemCount: temp },
      });
      localStorage.setItem("libraryName", item.topicName);
      localStorage.setItem("libraryId", item.id);
    },
  },
};
</script>

<style scoped>
@media (min-width: 1440px) and (max-width: 2560px) {
  .lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
</style>

<style scoped>
.cardTitle {
  position: relative;
  color: #fff;
  font-size: 20px;
  justify-content: center;
  top: 40px;
}
</style>
